 
@import "@aws-amplify/ui-react/styles.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
  --amplify-components-button-primary-background-color: #385f71;
  --amplify-components-link-color: #385f71;
  --amplify-components-tabs-item-active-color: #385f71;
  --amplify-components-tabs-item-active-border-color: #385f71;
  --amplify-components-tabs-item-background-color: #385f71;
}
